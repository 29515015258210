import React, { useEffect, useState } from 'react'
import SimpleSearch from '../../forms/searchbar/SimpleSearch'
import SingleSelect from '../../forms/select/SingleSelect';
import { getAllCities } from '../../../api/GET';
const priceOptions = [
    { value: 'OriginalListPrice.gte=1000000&OriginalListPrice.lte=2000000', label: '$1M - $2M' },
    { value: 'OriginalListPrice.gte=2000000&OriginalListPrice.lte=3000000', label: '$2M - $3M' },
    { value: 'OriginalListPrice.gte=3000000&OriginalListPrice.lte=5000000', label: '$3M - $5M' },
    { value: 'OriginalListPrice.gte=5000000', label: 'Over $5M' },
];


export const bedsOptions = [
    { value: 'BedroomsTotal=1', label: '1 Bed' },
    { value: 'BedroomsTotal=2', label: '2 Beds' },
    { value: 'BedroomsTotal=3', label: '3 Beds' },
    { value: 'BedroomsTotal=4', label: '4 Beds' },
    { value: 'BedroomsTotal.gte=5', label: '5+ Beds' },
];

export const bathOptions = [
    { value: 'BathroomsTotalInteger=1', label: '1 Bath' },
    { value: 'BathroomsTotalInteger=2', label: '2 Baths' },
    { value: 'BathroomsTotalInteger=3', label: '3 Baths' },
    { value: 'BathroomsTotalInteger.gte=4', label: '4+ Baths' },
];

const saleOptions = [
    { value: 'ListingAgreement=Exclusive Right To Sell', label: 'For Sale' },
];

const Filter = ({setfitlerQuery}) => {
    const [locationData, setLocationData] = useState([])
    const [filterValue, setFilterValue] = useState({
        BedroomsTotal:'',
        BathroomsTotalInteger:'',
        City:'',
        ListingAgreement:'',
        OriginalListPrice:'',

    })

    useEffect(() => {
        const fetchCities = async () => {
            const data = await getAllCities();

            if (Array.isArray(data?.data?.uniqueCities)) {
                setLocationData(data.data.uniqueCities.map(v => ({ label:v,value:`City=${v}` })))
            }

        }
        fetchCities()
    }, [])

    const handleChange=(key,value)=>{
setFilterValue(prev=>({...prev,[key]:value}))
    }
const handleSearch=()=>{
    const filterQuery=Object.values(filterValue).filter(v=>v)
    setfitlerQuery?.(filterQuery.join('&'))
}
    return (
        <>
            <div className='flex gap-4 w-full flex-wrap '>
                <div className='flex-1 max-w-[20vw]'>
                    <p className='pl-2 text-white'>Search By Location</p>
                    <SingleSelect options={locationData} value={filterValue.City} onChange={(v)=>handleChange('City',v)} placeholder={'Enter a location or a Keyword'} clsMain={' w-full min-w-[150px] md:min-w-[20vw] xl:min-w-[20vw]  rounded-[20px]'} />
                </div>


                <div className='flex-1'>
                    <p className='pl-2 text-white'>Price Range</p>
                    <SingleSelect value={filterValue.OriginalListPrice} onChange={(v)=>handleChange('OriginalListPrice',v)} clsMain={'w-full tracking-wider min-w-[150px] lg:min-w-[12vw]'} options={priceOptions} placeholder="Min Price - Max Price" />
                </div>

                <div className='flex-1'>
                    <p className='pl-2 text-white'>Beds</p>
                    <SingleSelect value={filterValue.BedroomsTotal} onChange={(v)=>handleChange('BedroomsTotal',v)} clsMain={'w-full tracking-wider  min-w-[100px] lg:min-w-[6vw]'} options={bedsOptions} placeholder="Any" />
                </div>

                <div className='flex-1'>
                    <p className='pl-2 text-white'>Bath</p>
                    <SingleSelect value={filterValue.BathroomsTotalInteger} onChange={(v)=>handleChange('BathroomsTotalInteger',v)} clsMain={'w-full tracking-wider min-w-[100px] lg:min-w-[6vw]'} options={bathOptions} placeholder="Any" />
                </div>

                <div className='flex-1'>
                    <p className='pl-2 text-white'>For Sale/All Type</p>
                    <SingleSelect value={filterValue.ListingAgreement} onChange={(v)=>handleChange('ListingAgreement',v)} clsMain={'w-full tracking-wider min-w-[150px] lg:min-w-[8vw]'} options={saleOptions} placeholder="For Sale/All Type" />
                </div>


                <div className='flex items-end'>
                    <button onClick={handleSearch} className='p-4 bg-white rounded-[20px] text-[19px] creato-f-r lg:w-[10vw] text-theme_yellow btn-primary'> Save Search</button>
                </div>
            </div>
        </>
    )
}

export default Filter