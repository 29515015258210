import React from 'react';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import { FaUser } from 'react-icons/fa';
import { CiLogin } from 'react-icons/ci';
const LoggedInUser = ({ name, handleLogout, isScrolled }) => {
    const items = [

        {
            key: '1',
            label: (
                <p  >
                    Logout
                </p>
            ),
            icon: <CiLogin />,
            onClick: handleLogout
        },

    ];

    return <Dropdown
        menu={{
            items,
        }}

    >
        <a onClick={(e) => e.preventDefault()}>
            <Space>
                <Avatar style={{ backgroundColor: isScrolled ? '#022541' : '#ffff', color: !isScrolled ? '#022541' : '#ffff', cursor: 'pointer' }} size={40}>{name ? name.slice(0, 1) : <FaUser />}</Avatar>
            </Space>
        </a>
    </Dropdown>
};
export default LoggedInUser;