import React, { useEffect, useRef, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import ImageViewer from '../components/image-viewer/ImageViewer'
import image1 from '../assets/images/card1.png'
import image2 from '../assets/images/card2.png'
import image3 from '../assets/images/card3.png'
import image4 from '../assets/images/card1.png'
import image5 from '../assets/images/card2.png'
import bed from '../assets/images/bed.png'
import bath from '../assets/images/bath.png'
import area from '../assets/images/area.png'
import sec4Logo from '../assets/images/sec4_logo.svg'
import Button from '../components/buttons/Button'
import { IoIosArrowForward, IoIosShareAlt } from 'react-icons/io'
import { FaLocationDot } from 'react-icons/fa6'
import { BsCircleFill } from 'react-icons/bs'
import MapComponent from '../components/map/Map'
import DatePicker from '../components/date-time-picker/DateTimePicker'
import AgentCard from '../components/cards/AgentCard'
import AnimationDiv from '../components/animation/AnimationDiv'
import Card from '../components/cards/Card'
import { cardVariants } from './New100Listing'
import { Link, useParams } from 'react-router-dom'
import { getListingByID, getListingPropertyData } from '../api/GET'
const images = [
    image1, image2, image3, image4, image5
];


const SingleProperty = () => {
    const { id } = useParams()
    const [allData, setallData] = useState({
        "City": "Miami",
        "PropertySubType": "Single Family Residence",
        "ListingID": "9f0b977f770df6a13c26f594d614fd77",
        "ListingInfo": {
            "StreetAddress": {
                "StreetNumber": "1085",
                "StreetName": "48th St",
                "StreetDirPrefix": "NW",
                "PostalCode": "33127"
            },
            "Location": {
                "Latitude": 25.819408,
                "Longitude": -80.213922,
                "CountyOrParish": "Miami-Dade County",
                "State": "FL",
                "Subdivision": "CRESTWOOD"
            },
            "PriceInfo": {
                "ListPrice": 195000,
                "ClosePrice": 170000,
                "PreviousListPrice": null,
                "DaysOnMarket": 27
            },
            "MLSDetails": {
                "MlsStatus": "Closed",
                "ListingAgreement": "Exclusive Right To Sell",
                "ListingId": "A10234481",
                "StandardStatus": "Closed",
                "OriginalListPrice": 195000,
                "CloseDate": "2018-04-19",
                "ListingContractDate": "2017-03-02"
            }
        },
        "PropertyDetails": {
            "YearBuilt": 1939,
            "Bedrooms": 3,
            "BathroomsTotal": 2,
            "LivingArea": 1187,
            "PropertyType": "Residential",
            "LotSize": {
                "Units": "Square Feet",
                "Dimensions": null,
                "Acres": null
            },
            "Cooling": [
                "Central Air",
                "Electric"
            ],
            "Heating": [
                "Central",
                "Electric"
            ],
            "WaterSource": [
                "Municipal Water"
            ],
            "Sewer": [
                "Other"
            ],
            "RoofType": [
                "Barrel Roof"
            ],
            "PoolPrivateYN": false,
            "WaterfrontYN": false,
            "View": [
                "Other"
            ],
            "Flooring": [
                "Ceramic Floor"
            ],
            "Appliances": [
                "Dryer",
                "Electric Range",
                "Refrigerator",
                "Washer"
            ],
            "InteriorFeatures": [
                "First Floor Entry",
                "Walk-In Closet(s)",
                "Family Room"
            ]
        },
        "AgentDetails": {
            "ListAgent": {
                "FullName": "Ivette Amador",
                "DirectPhone": "786-346-0986",
                "Email": "iamador4@yahoo.com",
                "LicenseNumber": null,
                "Office": {
                    "Name": "Miami New Realty",
                    "Phone": "305-485-3100",
                    "Fax": "305-437-7688",
                    "MlsId": "MNRE01"
                }
            },
            "BuyerAgent": {
                "FullName": "Ivette Amador",
                "DirectPhone": "786-346-0986",
                "Email": "iamador4@yahoo.com",
                "LicenseNumber": null
            }
        },
        "Photos": [

        ],
        "PublicRemarks": {
            "ShortSaleYN": false,
            "LeaseConsideredYN": false,
            "Remarks": "Seller must keep this listing active for five (5) days, including a weekend. An offer can be submitted at any time; but Seller can not accept any offer until the required five-day marketing period ends. Buyers' agents must structure their offers accordingly."
        }
    })


    const [properties, setProperties] = useState([])
    useEffect(() => {
        const fetchProperties = async () => {

            const data = await getListingPropertyData('');
            if (Array.isArray(data.data?.data)) {
                setProperties(data.data?.data)
            }
        }

        fetchProperties()
    }, [])


    const overviewRef = useRef(null);
    const [isStuck, setIsStuck] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsStuck(entry.isIntersecting);
            },
            {
                rootMargin: '0px',
                threshold: 0.1, // Adjust this threshold as needed
            }
        );

        if (overviewRef.current) {
            observer.observe(overviewRef.current);
        }

        return () => {
            if (overviewRef.current) {
                observer.unobserve(overviewRef.current);
            }
        };
    }, []);
    useEffect(() => {

        const fetchData = async () => {
            const data = await getListingByID(id);

            if (data.data && data.data[0]) {
                setallData(data.data[0])
            }
        }
        fetchData()
    }, [])



    return (
        <ContactLayout>
            <div className="md:max-w-[80%] mx-auto py-4 px-4">
                <ImageViewer intent={allData.ListingInfo.MLSDetails.ListingAgreement} images={allData?.Photos ? allData.Photos.map(v => v.MediaURL) : images} />

                <div className='flex justify-between container mx-auto p-4'>
                    <div>
                        <h1 className='creato-f-b text-[20px] md:text-[1.3vw]'>{`${allData?.ListingInfo?.StreetAddress?.PostalCode} ${allData?.ListingInfo?.Location?.CountyOrParish}`}</h1>
                        <div className="flex items-center gap-2  mt-2  ">
                            <img src={bed} alt='' className='w-[1.2vw]' /> <p className='text-[#101010]  text-[15px] lg:text-[0.7vw]  poppins-regular text-nowrap leading-[23px]'>{allData?.PropertyDetails?.Bedrooms} Bed</p>
                            <img src={bath} alt='' className='w-[1.2vw]' /> <p className='text-[#101010]  text-[15px] lg:text-[0.7vw]  poppins-regular text-nowrap leading-[23px]'>{allData?.PropertyDetails?.BathroomsTotal} Bath</p>
                            <img src={area} alt='' className='w-[1.2vw]' /> <p className='text-[#101010]  text-[15px] lg:text-[0.7vw]  poppins-regular text-nowrap leading-[23px]'>{allData?.PropertyDetails?.LivingArea} SQFT</p>
                            <p className='text-[15px] lg:text-[0.7vw]  text-green-500'>{allData?.ListingInfo?.MLSDetails?.StandardStatus}</p>
                        </div>
                        <div className='flex gap-4 lg:gap-16 items-center my-4'> <h2 className='creato-f-b text-[30px] lg:text-[2.7vw] text-nowrap'> ${allData?.ListingInfo?.PriceInfo?.OriginalListPrice ? allData?.ListingInfo?.PriceInfo?.OriginalListPrice?.toLocaleString() : allData?.ListingInfo?.PriceInfo?.ListPrice?.toLocaleString()}</h2> <Button className={'creato-f-b text-theme_yellow bg-theme_blue py-2 rounded-md'} title={<div className='flex items-center gap-2 '><span>Share</span> <span><IoIosShareAlt /></span></div>} /></div>
                        <div className="flex items-center gap-1  " >
                            <FaLocationDot className='text-[1vw]' /> <p className='text-[#101010] poppins-regular	 text-[15px] lg:text-[0.7vw]  text-nowrap leading-[18px]'>{allData?.City}
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={sec4Logo} alt="" className='my-4' />
                    </div>
                </div>
                <div className='container items-center flex-wrap gap-4 flex md:justify-between mx-auto p-4 border-theme_grey2 border-b-2 pb-[1.5%] my-4'>
                    <div className="flex creato-f-b gap-2 md:gap-10 text-theme_yellow md:text-[30px] lg:text-[1.2vw] "><a href='#overview' className="">Overview</a>
                        <a href='#features' className="text-nowrap">Features & Amenities</a>
                        <a href='#location' className="">Location</a></div>
                    <div className="flex gap-6 items-center">
                        <Button className={'border border-theme_blue rounded-full text-theme_yellow creato-f-b bg-white py-3 px-6'} title={'Get in Touch'} />
                        <Button className={'rounded-full creato-f-b bg-theme_blue py-3 btn2 px-6'} title={'Schedule a Tour'} />
                    </div>
                </div>
                <div className="container flex items-center lg:items-start lg:justify-between flex-col lg:flex-row mx-auto ">
                    <div className="w-full">
                        <div id="overview">
                            <h2 className="text-[35px] lg:text-[45px] xl:text-[3vw] creato-f-b text-theme_yellow">
                                Property Description
                            </h2>
                            <h2 className="text-[25px] lg:text-[2.4vw] creato-f-b mt-2">The Property</h2>
                            <p className="creato-f-b md:text-[.84vw] lg:max-w-[42.5vw] py-6">
                                {allData?.PublicRemarks?.Remarks}
                            </p>
                        </div>

                        <div className="lg:max-w-[42.5vw]">
                            <h2 className="text-[25px] lg:text-[2.36vw] creato-f-b mt-2 mb-8">Overview</h2>

                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue">
                                <div className="flex-1"><h2 className="creato-f-b">Property Type</h2></div>
                                <div className="flex-1"><p>{allData?.PropertySubType}</p></div>
                            </div>
                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue">
                                <div className="flex-1"><h2 className="creato-f-b">Location</h2></div>
                                <div className="flex-1">
                                    <p>{`${allData?.City}, ${allData?.ListingInfo?.Location?.State}`}</p>
                                </div>
                            </div>
                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue">
                                <div className="flex-1"><h2 className="creato-f-b">MLS ID</h2></div>
                                <div className="flex-1"><p>{allData?.ListingInfo?.MLSDetails?.ListingId}</p></div>
                            </div>
                            <div className="flex md:text-[1.1vw] px-4 py-[3.5%] border-t border-theme_blue border-b">
                                <div className="flex-1"><h2 className="creato-f-b">LISTING DATE</h2></div>
                                <div className="flex-1">
                                    <p>{(allData?.ListingInfo?.MLSDetails?.ListingContractDate)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="lg:max-w-[42.5vw] mt-12" id="features">
                            <h2 className="text-[25px] lg:text-[2.36vw] creato-f-b mt-2 mb-8">
                                Property Features & Amenities
                            </h2>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Interior
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Total Bedrooms</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.Bedrooms}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Total Bathrooms</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.BathroomsTotal}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Living Area</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{`${allData?.PropertyDetails?.LivingArea} sq ft`}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Appliances</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.Appliances?.join(", ")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Exterior
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Year Built</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.YearBuilt}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Roof Type</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.PropertyDetails?.RoofType?.join(", ")}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Details
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Subdivision</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.ListingInfo?.Location?.Subdivision}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4 border-b border-theme_blue">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Address</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">
                                            {`${allData?.ListingInfo?.StreetAddress?.StreetNumber} ${allData?.ListingInfo?.StreetAddress?.StreetDirPrefix} ${allData?.ListingInfo?.StreetAddress?.StreetName}`}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 mt-4">
                                <h3 className="pt-4 text-[20px] lg:text-[1.6vw] text-theme_yellow creato-f-b">
                                    Financial
                                </h3>
                                <div className="flex md:text-[1.1vw] py-4">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">List Price</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{(allData?.ListingInfo?.PriceInfo?.ListPrice)}</p>
                                    </div>
                                </div>
                                <div className="flex md:text-[1.1vw] py-4">
                                    <div className="flex-1 flex gap-4 items-center">
                                        <BsCircleFill className="text-xs" />
                                        <h2 className="creato-f-b">Days on Market</h2>
                                    </div>
                                    <div className="flex-1">
                                        <p className="creato-f-b">{allData?.ListingInfo?.PriceInfo?.DaysOnMarket}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`pt-0 px-4  relative`}>
                        <div className={`${isStuck ? ' sticky top-0 right-0 ' : ''} `}>
                            <DatePicker allData={allData} />
                            <div className='mt-24'>
                                <AgentCard />
                            </div>
                        </div>

                    </div>
                </div>
                <div id="location" className='w-full my-8'>
                    <div className='container mx-auto '>
                        <h2 className=' text-[25px] lg:text-[2.36vw] creato-f-b mt-2 mb-8'>Location</h2>
                    </div>

                    <div className="w-full h-[400px]">
                        <MapComponent coords={allData?.ListingInfo?.Location} nameLocation={allData?.ListingInfo?.StreetAddress?.PostalCode + ' ' + allData?.ListingInfo?.Location?.CountyOrParish} />
                    </div>
                </div>

                <div className='container py-20 mx-auto creato-f-b text-theme_blue'>
                    <h2 className=' text-[25px] lg:text-[2.36vw] mt-2 '>Discover More!</h2>
                    <p className='max-w-[1000px] lg:text-[1.1vw]'>
                        Discover our expertly curated collection of properties for sale. With a focus on quality and value, we offer
                        some of the best properties on the market.
                    </p>

                    <div className='flex justify-center flex-wrap gap-x-8 mt-10'>
                        {/* {[properties.slice(0, 3)].map(v => <AnimationDiv
                            key={v.name}
                            className="lg:w-[20vw]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                            variants={cardVariants}
                        > <Card data={v} key={v.name} className={`lg:w-[20vw] shadow-lg`} /> </AnimationDiv>)} */}

                        {Array.isArray(properties) && properties.slice(0, 3).map(v => <AnimationDiv
                            key={v.name}
                            className="lg:w-[20vw]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.2 }}
                            variants={cardVariants}
                        > <Card data={v} key={v.name} className={`lg:w-[20vw] shadow-lg`} property_url={true} /> </AnimationDiv>)}
                    </div>

                    <Link to="/new-100/condos">
                        <div className='flex justify-center mt-14'>
                            <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_blue px-8 text-theme_yellow rounded-full py-3 creato-f-m  tracking-wide btn2'} />
                        </div>
                    </Link>
                </div>
            </div>
        </ContactLayout>
    )
}

export default SingleProperty