import axios from "axios"
import { API_BASE_URL } from "../../config"

export const getHomeProperties = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?limit=15&sortBy=OriginalListPrice&order=desc&StandardStatus=Active&OriginalListPrice.gte=1000000&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return response

    } catch (error) {

    }

}
export const getProperties = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&StandardStatus=Active&OriginalListPrice.gte=1000000`);

        return response

    } catch (error) {

    }

}
export const getCondos = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&StandardStatus=Active&PropertySubType.in=Condominium&PropertySubType.in=Apartment&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);
        return response

    } catch (error) {

    }

}
export const getHome = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?sortBy=OnMarketDate&order=desc&StandardStatus=Active&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&limit=50&OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell`);
        return response

    } catch (error) {

    }

}

export const getListingPropertyData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&StandardStatus=Active&ListingAgreement=Exclusive Right To Sell&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse`);

        return response

    } catch (error) {

    }

}
export const getListingData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?StandardStatus=Active${query}`);

        return  {data:{...response.data,
            data:response.data.data?.filter(v=>v.ListingID)
        }}

    } catch (error) {

    }

}
export const getListingExpensiveData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&limit=100&sortBy=OriginalListPrice&order=desc&StandardStatus=Active&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return response

    } catch (error) {

    }

}
export const getListingHomeData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&StandardStatus=Active&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`);

        return response

    } catch (error) {

    }

}
export const getListingCondosData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&StandardStatus=Active&PropertySubType.in=Condominium&PropertySubType.in=Apartment&ListingAgreement=Exclusive Right To Sell`);

        return response

    } catch (error) {

    }

}
export const getListingVillaData = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings?${query}&sortBy=OnMarketDate&order=desc&StandardStatus=Active&PropertySubType.in=Townhouse&ListingAgreement=Exclusive Right To Sell`);

        return  {data:{...response.data,
            data:response.data.data?.filter(v=>v.ListingID)
        }}

    } catch (error) {

    }

}
export const getListingByID = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}listings/${id}`);
        
        return response

    } catch (error) {

    }

}

export const getPropertyTypes = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}get-property-sub`);

        return response

    } catch (error) {

    }

}
export const getAllCities = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}get-cities`);

        return response

    } catch (error) {

    }
}
export const getSinglePreConstruction = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}getPreConstruction/${id}`);

        return response

    } catch (error) {

    }
}