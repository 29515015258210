import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import card1 from '../../assets/images/sec_3_1.png'
import card2 from '../../assets/images/sec_3_2.png'
import card3 from '../../assets/images/sec_3_3.png'
import bed from '../../assets/images/bed.png'
import bath from '../../assets/images/bath.png'
import sqfeetwhite from '../../assets/images/sqfeetwhite.svg'
import Button from '../buttons/Button';
import { IoBedSharp } from 'react-icons/io5';
import { FaChevronLeft, FaChevronRight, FaShower } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import axios from 'axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { API_BASE_URL } from '../../config';

import Image from '../../assets/images/android-chrome-512x512.png'
import { Link } from 'react-router-dom';





const fadeVariants = {
    hidden: { opacity: 0.2 },
    visible: { opacity: 1 },
};

const LuxuryListingsSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
const [listingData, setlistingData] = useState([])
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % listingData.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + listingData.length) % listingData.length);
    };

    const visibleListings = [
        listingData[currentIndex],
        listingData[(currentIndex + 1) % listingData.length],
        listingData[(currentIndex + 2) % listingData.length]
    ];
const fetchData=async()=>{
  try {
    const res=await axios.get(`${API_BASE_URL}listings?limit=15&sortBy=OriginalListPrice&order=desc&StandardStatus=Active&OriginalListPrice.gte=1000000&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&ListingAgreement=Exclusive Right To Sell`)
    setlistingData(res.data?.data?.filter(v=>v.ListingID))
  } catch (error) {
    
  }
}

useEffect(() => {
  fetchData()
}, [])


    return (
     Array.isArray(listingData)&&listingData.length>2?   <div className='bg-theme_blue flex flex-col justify-center py-16 '  >
            <div className='p-4 flex mx-auto'>
                <div className='p-4 py-16 flex mx-auto gap-4'>
                    {/* Left side content */}
                    <div className="">
                        <div className="text-white max-w-[335px] md:max-w-[35vw] text-center lg:mx-auto">
                            <h2 className='text-[35px] md:text-[2.8vw] creato-f-m md:leading-[2.8vw]'>Luxlife's</h2>
                            <h2 className='text-[25px]  md:text-[2.3vw]'>Top 100</h2>
                            <p className='text-sm lg:text-[0.9vw] tracking-wider text-wrap'>
                                Luxlife's Top 100 shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!
                            </p>
                        </div>
                        {/* First two listings */}
                        <div className="flex gap-6 mt-10">
                            {visibleListings.slice(0, 2).map((listing, index) => (
                                  <Link to={`/single-property/${listing?.ListingID}`}  key={index} >
                                <div key={index} className="relative dark-bottom-overlay text-white group overflow-hidden cursor-pointer">
                                    <img src={listing?.Photos && listing?.Photos[0]?.MediaURL ? listing?.Photos[0]?.MediaURL : Image} alt="" className='min-w-[300px] w-[17.5vw] h-[500px] lg:h-[26vw] object-cover group-hover:scale-[1.1] transition duration-500' />
                                    <div className='absolute left-2 z-30 bottom-4 pl-[10px]'>
                                        <Button className={'bg-theme_blue text-[14px] lg:text-[0.8vw] tracking-widest text-white px-8 rounded-full py-2 btn2'} title={'For Sale'} />
                                        <h2 className='poppins-bold md:text-[1.2vw] mt-2'>$ {listing?.ListingInfo?.PriceInfo?.OriginalListPrice ? listing?.ListingInfo?.PriceInfo?.OriginalListPrice : listing?.ListingInfo?.PriceInfo?.ListPrice}</h2>
                                        <p className='text-[12px] lg:text-[0.6vw]  leading-[15px]'>{listing?.ListingInfo?.StreetAddress?.PostalCode}, {listing?.ListingInfo?.Location?.CountyOrParish}</p>
                                        <div className="flex items-center gap-2 text-[#fff] creato-f-r mt-1">
                                            <IoBedSharp className='text-[20px] mb-1' /><p className='text-[12px] text-nowrap leading-[18px]'>{listing?.PropertyDetails?.Bedrooms} Bed</p>
                                            <FaShower className='text-[20px] mb-1' /> <p className='text-[12px] text-nowrap leading-[18px]'>{listing?.PropertyDetails?.BathroomsTotal} Bath</p>
                                            <img src={sqfeetwhite} alt='' className='w-5' /> <p className='text-[12px] text-nowrap leading-[18px]'>{listing?.PropertyDetails?.LivingArea} SQFT</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            ))}
                        </div>
                    </div>

                    {/* Right side with animated image */}
                    <div className="lg:flex justify-end items-end hidden">
                        <AnimatePresence mode="wait">
                        <Link to={`/single-property/${visibleListings[2]?.ListingID}`}>
                            <motion.div
                                key={currentIndex}
                                variants={fadeVariants}
                                initial="hidden"
                                animate="visible"
                                exit="hidden"
                                transition={{ duration: 0.3 }}
                                className="relative dark-bottom-overlay text-white group overflow-hidden cursor-pointer"
                            >
                                <img src={visibleListings[2]?.Photos?.[0]?.MediaURL??Image} alt="" className='lg:w-[27.5vw] h-[38vw] object-cover group-hover:scale-[1.1] transition duration-500' />
                                <div className='absolute left-4 z-30 bottom-4 pl-[10px]'>
                                    <Button className={'bg-theme_blue text-[14px] tracking-widest text-white px-8 rounded-full py-2 btn2'} title={'For Sale'} />
                                    <h2 className='poppins-bold md:text-[24px] mt-2'>$  {visibleListings[2]?.ListingInfo?.PriceInfo?.OriginalListPrice ? visibleListings[2]?.ListingInfo?.PriceInfo?.OriginalListPrice : visibleListings[2]?.ListingInfo?.PriceInfo?.ListPrice}</h2>
                                    <p className='text-[12px] leading-[15px]'>{visibleListings[2]?.ListingInfo?.StreetAddress?.PostalCode}, {visibleListings[2]?.ListingInfo?.Location?.CountyOrParish}</p>
                                    <div className="flex items-center gap-2 text-[#fff] creato-f-r">
                                    <IoBedSharp className='text-[20px] mb-1' /><p className='text-[12px] text-nowrap leading-[18px]'>{visibleListings[2]?.PropertyDetails?.Bedrooms} Bed</p>
                                            <FaShower className='text-[20px] mb-1' /> <p className='text-[12px] text-nowrap leading-[18px]'>{visibleListings[2]?.PropertyDetails?.BathroomsTotal} Bath</p>
                                            <img src={sqfeetwhite} alt='' className='w-5' /> <p className='text-[12px] text-nowrap leading-[18px]'>{visibleListings[2]?.PropertyDetails?.LivingArea} SQFT</p>
                                    </div>
                                </div>
                            </motion.div>
                            </Link>
                        </AnimatePresence>
                    </div>
                </div>
            </div>

            {/* Navigation buttons */}
            <div className='flex justify-between items-center lg:max-w-[70%] px-4 w-full mx-auto'>
                <div className='flex justify-center items-center gap-4'>
                    <div onClick={prevSlide} className="p-4 rounded-full text-white hover:scale-[1.1] border-white border transition duration-300 cursor-pointer bg-theme_blue">
                        <FaChevronLeft className='text-[18px]' />
                    </div>
                    <div onClick={nextSlide} className="p-4 rounded-full text-white hover:scale-[1.1] border-white border transition duration-300 cursor-pointer bg-theme_blue">
                        <FaChevronRight className='text-[18px]' />
                    </div>
                </div>
                <div className='flex justify-center'>
                <Link to={'/new-100/listing'}>
                    <Button
                        title={
                            <div className='flex items-center gap-4'>
                                <span>View more</span>
                                <span><IoIosArrowForward className='text-[20px]' /></span>
                            </div>
                        }
                        className={'bg-theme_yellow px-8 text-white rounded-full py-3 max-h-[50px] creato-f-m tracking-wide btn2'}
                    />
                    </Link>
                </div>
            </div>
        </div>: <Spin size="large" spinning={true} indicator={<LoadingOutlined spin />}>
                         <div className='min-h-[100px] w-full'> </div> 

                    </Spin>
    );
};

export default LuxuryListingsSlider;