import React, { useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import hero from '../assets/images/sell_property_hero.png'
import sec5logo from '../assets/images/sec5_logo.svg'
import Uploader from '../components/forms/uploader/Uploader'
import { bathOptions, bedsOptions } from '../components/ui/filter/Filter'
import SingleSelect from '../components/forms/select/SingleSelect'
import AgentCard from '../components/cards/AgentCard'
const SellProperties = () => {
    const [fileList, setFileList] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      topic: '',
      beds: '',
      baths: '',
      message: '',
      terms: false
    });
  
    const validateForm = () => {
      const newErrors = {};
      
      // Name validation
      if (!formData.name.trim()) {
        newErrors.name = 'Name is required';
      }
  
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!formData.email.trim() || !emailRegex.test(formData.email)) {
        newErrors.email = 'Valid email is required';
      }
  
      // Phone validation
     
  
      // Topic validation
      if (!formData.topic.trim()) {
        newErrors.topic = 'Topic is required';
      }
  
      // Message validation
      if (!formData.message.trim()) {
        newErrors.message = 'Message is required';
      }
  
      // Terms validation
      if (!formData.terms) {
        newErrors.terms = 'You must accept the terms';
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    };
  
    const handleSelectChange = (key, value) => {
      setFormData(prev => ({
        ...prev,
        [key]: value
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      
      if (validateForm()) {

                // axios.post('/api/submit-property', { ...formData, files: fileList })
      } else {
        console.log('Form has errors');
      }
    };
  
    return (
        <ContactLayout>
            <div className="relative w-full max-h-[500px] md:max-h-[600px] min-h-[300px]  lg:max-h-[700px]">
                {/* Image with full width and object cover to maintain aspect ratio */}
                <img src={hero} alt="Hero Image" className="w-full h-full max-h-[500px] md:max-h-[600px] min-h-[300px]  lg:max-h-[700px]   object-cover" />

                {/* Overlay with semi-transparent black background */}
                <div className="absolute inset-0 bg-black opacity-50 "></div>

                {/* Centered text block */}
                <div className="absolute top-1/2 transform -translate-y-1/2 text-center text-white px-4 md:px-8 w-full ">
                    <h1 className="text-[24px] md:text-[48px] lg:text-[2.8vw] creato-f-b mx-auto">
                        Thinking of Selling Your Condo or Home?
                    </h1>
                    <p className="text-[16px] md:text-[20px] lg:text-[23px] creato-f-m mt-2">
                        Enter Your Property Details below and get a free Market Analysis report for your Property!
                    </p>
                </div>
            </div>


            <div className='sell-property-bg bg-theme_blue py-12 px-4'>
      <div className='lg:max-w-[60%] mx-auto'>
        <div className='flex justify-center lg:justify-between flex-col-reverse lg:flex-row'>
          <div className="lg:max-w-[30vw] text-center mx-auto lg:text-start lg:mx-0">
            <h2 className='text-white creato-f-m text-[30px] md:text-[2.5vw] mt-4'>
              Fill Your Property Details Here And Upload Images
            </h2>
          </div>
          <div className="flex justify-center">
            <img src={sec5logo} alt="" className='w-[150px] md:w-[16vw]' />
          </div>
        </div>

        <div className='flex justify-center lg:justify-between gap-[3%] flex-wrap items-center lg:items-start mt-14'>
          <div className="max-w-[650px] w-full lg:max-w-[26vw] bg-white flex1 p-8 rounded-[30px] overflow-hidden">
            <Uploader setFileList={setFileList} fileList={fileList} />
          </div>
          
          <form onSubmit={handleSubmit} className="flex-1 max-w-[650px] lg:max-w-[30vw] mt-[2%] lg:mt-0">
            <div className='p-2 py-4 md:p-6 bg-white rounded-3xl'>
              <div className='w-full'>
                <div className='flex flex-col w-full px-4 md:px-[4%] my-4'>
                  <input 
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder='Name *' 
                    className={`focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-300 border p-[3%] w-full rounded-xl ${errors.name ? 'border-red-500' : ''}`} 
                  />
                  {errors.name && <span className="text-red-500 text-sm mt-1">{errors.name}</span>}
                </div>

                <div className='flex flex-col w-full px-4 md:px-[4%] my-4'>
                  <input 
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder='Email *' 
                    className={`focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-300 border p-[3%] w-full rounded-xl ${errors.email ? 'border-red-500' : ''}`}
                  />
                  {errors.email && <span className="text-red-500 text-sm mt-1">{errors.email}</span>}
                </div>

                <div className='flex flex-col w-full px-4 md:px-[4%] my-4'>
                  <input 
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder='Phone *' 
                    className={`focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-300 border p-[3%] w-full rounded-xl ${errors.phone ? 'border-red-500' : ''}`}
                  />
                  {errors.phone && <span className="text-red-500 text-sm mt-1">{errors.phone}</span>}
                </div>

                <div className='flex flex-col w-full px-4 md:px-[4%] my-4'>
                  <input 
                    name="topic"
                    value={formData.topic}
                    onChange={handleInputChange}
                    placeholder='Topic *' 
                    className={`focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-300 border p-[3%] w-full rounded-xl ${errors.topic ? 'border-red-500' : ''}`}
                  />
                  {errors.topic && <span className="text-red-500 text-sm mt-1">{errors.topic}</span>}
                </div>

                <div className='flex justify-between flex-wrap-reverse items-start px-4 md:px-[4%] gap-2'>
                  <div className='flex flex-col flex-1 mt-4 min-w-[150px]'>
                    <div className='flex gap-[3%]'>
                      <div className=''>
                        <p className='pl-2 text-theme_grey2'>Beds</p>
                        <SingleSelect 
                          value={formData.beds} 
                          onChange={(v) => handleSelectChange('beds', v)} 
                          clsMain={'w-full tracking-wider min-w-[100px] lg:min-w-[5vw]'} 
                          options={bathOptions} 
                          placeholder="Any" 
                        />
                      </div>
                      <div className=''>
                        <p className='pl-2 text-theme_grey2'>Bath</p>
                        <SingleSelect 
                          value={formData.baths} 
                          onChange={(v) => handleSelectChange('baths', v)} 
                          clsMain={'w-full tracking-wider min-w-[100px] lg:min-w-[5vw]'} 
                          options={bedsOptions} 
                          placeholder="Any" 
                        />
                      </div>
                    </div>

                    <div className='flex w-full my-4 gap-1'>
                      <input 
                        id='terms' 
                        name="terms"
                        type='checkbox'
                        checked={formData.terms}
                        onChange={handleInputChange}
                        className={`focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-[3%] align-top self-start rounded-xl ${errors.terms ? 'border-red-500' : ''}`} 
                      />
                       <label htmlFor='terms' className='text-[9px] lg:text-[0.5vw]  text-[#022541] creato-f-l z-10 tracking-wide'>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'>Privacy Policy</span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices. This consent isn’t necessary for purchasing any products or services and you may opt out at any time. To opt out from texts, you can reply, ‘stop’ at any time. To opt out from emails, you can click on the unsubscribe link in the emails. Message and data rates may apply.
                       </label>
                    </div>
                    {errors.terms && <span className="text-red-500 text-sm ml-4">{errors.terms}</span>}
                  </div>

                  <div className='flex flex-col w-full flex-1 min-w-[10vw] my-4'>
                    <textarea 
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      placeholder='Your Message *' 
                      className={`focus:outline-none placeholder:text-gray-500 placeholder:opacity-80 tracking-widest border-gray-300 border p-[3%] w-full rounded-xl ${errors.message ? 'border-red-500' : ''}`}
                      rows={10}
                    />
                    {errors.message && <span className="text-red-500 text-sm mt-1">{errors.message}</span>}
                  </div>
                </div>

                <div className='flex w-full px-4 md:px-8 my-4 mt-[20px]'>
                  <button 
                    type="submit"
                    className='btn-primary py-3 bg-theme_yellow text-white rounded-full text-nowrap px-14 font-extrabold tracking-widest'
                    style={{ fontSize: 14 }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

            <div className='flex lg:max-w-[75%] mx-auto gap-8 flex-wrap justify-center mt-8'>
                <div className='min-w-[300px] md:max-w-[60%]'>
                    <div className="px-6 py-8  mx-auto creato-f-m text-theme_blue text-[16px] md:text-[0.98vw]">
                        <h2 className="text-[30px] md:text-[2.4vw] creato-f-b mb-4">HOW TO SELL YOUR CONDO IN TODAY'S REAL ESTATE MARKET</h2>
                        <p className="mb-6">
                            LuxLife presents a comprehensive market analysis to each of our sellers. We look at a number of factors that could support a quick sale and an optimal selling price. We will help you find a target price that could result in a sale in 30-60 days, or if you have more time, a price that could result in a sale in 3-6 months. This gives a seller two different selling strategies, each one meeting a different objective of the seller.
                        </p>

                        <h2 className="text-[30px] md:text-[2.4vw] creato-f-b mb-3">THE LUXLIFE PROGRAM</h2>
                        <p className="mb-4">
                            LuxLife has revolutionized the way that condos are sold. Today’s real estate market depends heavily on the Internet, yet the old-fashioned rituals of business relationships and one-on-one customer services are equally-important. LuxLife customers who want to sell their condos and homes find that the LuxLife Program is one of the most-effective ways to get to market.
                        </p>
                        <p className="mb-4">
                            Our team-approach to condo sales ensures us to be available to buyers and other brokers 24 hours a day. We do this via:
                        </p>
                        <ul className="list-disc marker:text-[1.4vw] ml-6 mb-6">
                            <li>24/7 phone coverage</li>
                            <li>24/7 e-mail coverage</li>
                            <li>
                                Technology Prowess: Every member of our team is equipped with state-of-the-art business technologies, web-based management of documents and marketing materials and an elaborate customer relationship management suite of tools.
                            </li>
                        </ul>

                        <p className="mb-4">
                            Our comprehensive sales and marketing program will ensure that your condo is given the maximum amount of exposure. Our marketing program includes:
                        </p>
                        <ul className="list-disc marker:text-[1.4vw] ml-6 mb-6">
                            <li>
                                Extensive photography of the property, including a panoramic virtual tour of the property and common areas
                            </li>
                            <li>
                                Inclusion in the MLS (multiple listing service), wherein tens and thousands of Realtors and brokers have access to the property listings. LuxLife MLS listings maximize the use of photos and virtual tours.
                            </li>
                            <li>
                                Regular communication with the national and international (Europe, Canada, South America) press, via press contacts, press releases and other methods whereby journalists learn of our properties for sale.
                            </li>
                            <li>
                                Extensive visibility to a global community of buyers through the Internet, including featured and prominent placement on Miami Real Estate on Zillow.com, Homes, Home for Sale, Mortgage Rates, Virtual Tours & Rentals | realtor.com® and a host of other high-traffic websites. Your condo’s details are propagated to hundreds of other websites around the world, by a sharing system that we participate in.
                            </li>
                            <li>
                                High-ranking visibility in Google and other search engines, subject to each search engine’s ranking criteria
                            </li>
                            <li>
                                Full-color, online brochures for your property, available for download in PDF format and available through our website
                            </li>
                            <li>
                                Continual e-mail marketing to our worldwide customer base, which includes regular e-mail blasts to hundreds of thousands of customers from around the world
                            </li>
                            <li>
                                Regular broker e-mail updates, reaching thousands of brokers both locally and from around the world
                            </li>
                        </ul>

                        <h2 className="text-[30px] md:text-[2.4vw] creato-f-b mb-3">
                            DANIEL PANSKY - YOUR BEST-CHOICE OF LISTING AGENT - HERE'S WHY
                        </h2>
                        <p className="mb-4">
                            There are many reasons why we are successful:
                        </p>
                        <ol className="list-decimal ml-6 mb-6">
                            <li>
                                We have a strong Internet presence in the Miami area. Buyers are most-likely to use the Internet when looking for properties, and our website is one of Miami’s most popular. So, we get a lot of buyer traffic.
                            </li>
                            <li>
                                We have access to hundreds of thousands of buyers and brokers via our e-mail lists, personal networks and other tools. We are also regularly interviewed and have been featured on national news television (ABC, CBS, NBC) and in national newspapers (Wall St. Journal, USA Today) as Miami real estate agents. This generates a lot of business for us (buyers making inquiries).
                            </li>
                            <li>
                                We make ourselves available any time, any day, including weekends and holidays. This way, we never miss a buyer inquiry. We are also generally able to respond to last-minute requests to show our listed properties. There is no “snooze and lose” in our firm!
                            </li>
                            <li>
                                We respond to buyers fast. We try to always return phone calls and e-mails in 30 minutes or less, and we do this 24 hours a day.
                            </li>
                            <li>
                                We spend a lot of time reaching out to other brokers, looking for their buyer contacts. This way, we maximize our reach. We realize that working with other brokers is very important, and we have relationships with most top-producing brokers in Miami.
                            </li>
                            <li>
                                We have a stunning track record for selling properties. Almost every condo we have taken to market has sold, and that is something that we are very proud of. Many of our sales have occurred within 6 months of listing the property, and some have sold in a matter of weeks.
                            </li>
                            <li>
                                Unlike most individual realtors, we work within a team structure. This means that you will have a primary REALTOR as your point of contact. Each realtor has a number of back-up realtors, and this ensures that we can always find time to show our listed properties to buyers and/or their brokers. So, whenever someone calls to see your property, we will have someone to show the property. Not many other realtors can make this claim.
                            </li>
                            <li>
                                We invest heavily in advertising. We are seen in Ocean Drive, Miami Herald and other publications on a regular basis.
                            </li>
                            <li>
                                We are smart business people. We follow our deals through their completion, ensuring the highest level of professionalism and eliminating any obstacles that could cause a deal to fall apart.
                            </li>
                            <li>
                                We see ourselves as your business partner, and we treat your property as if it was our own. We stay committed and loyal to you.
                            </li>
                        </ol>

                        <h2 className="text-[30px] md:text-[2.4vw] creato-f-b mb-4">WOULD YOU LIKE TO SPEAK TO US ABOUT SELLING YOUR CONDO OR HOME?</h2>
                    </div>
                </div>


                <div className='my-12 flex-1 flex justify-center items-start'>
                    <AgentCard />
                </div>
            </div>


        </ContactLayout>
    )
}

export default SellProperties