import React, { useState } from 'react';
import { GoChevronDown } from 'react-icons/go';

const SingleSelect = ({ options, placeholder, clsMain,onChange }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChange(option.value)
    };

    return (
        <div className={`relative ${clsMain}`}>
            <div
                className="flex justify-between items-center bg-white border border-gray-300 rounded-[20px] p-2 py-4 cursor-pointer"
                onClick={toggleDropdown}
            >
                <span className='text-theme_yellow text-nowrap'>{selectedOption ? selectedOption.label : <span className='text-gray-200'>{placeholder}</span>}</span>
                <GoChevronDown
                    className={`transform transition-transform text-2xl duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'
                        }`}
                />
            </div>

            {isOpen && (
                <ul className="absolute z-[1000000000] mt-1 w-full max-h-[400px] overflow-y-scroll bg-white border border-gray-300 rounded-lg shadow-lg">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SingleSelect;
