import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactLeft from '../../../../assets/images/contatct_bg_left.png';
import owner from '../../../../assets/images/owner_contatct.png';
import AnimationDiv from '../../../animation/AnimationDiv';
import { cn } from '../../../../lib/utils';

const ContactUs = ({ className }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        topic: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        const { name, email, phone, topic, message } = formData;
        if (!name || !email || !phone || !topic || !message) {
            toast.warning('All fields are required');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/contactus/store', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                toast.success('Contact request submitted successfully!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    topic: '',
                    message: '',
                });
            } else {
                toast.error('Failed to submit contact request. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting contact request:', error);
            toast.error('An error occurred. Please try again later.');
        }
    };

    return (
        <>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} />
            <div className={cn(`flex mt-24 flex-wrap flex-col lg:flex-row  `, className)}>
                <div className='flex-1 hidden md:block relative bg-theme_yellow'>
                    <img src={ContactLeft} alt="" width={'100%'} height={'70%'} className='h-[100%] object-cover' />
                    <div className='absolute my-auto top-[47%] right-[40px] '>
                        <h2 className='text-6xl font-sans font-extrabold text-white'>Get in Touch.</h2>
                    </div>
                </div>
                <div className='flex-1 md:bg-theme_yellow contact_right_bg   relative'>
                    <div className='md:hidden text-start py-6 mt-12 mx-auto w-[90%]'>
                        <h2 className='text-[45px] md:text-6xl font-sans font-extrabold text-white'>Get in Touch.</h2>
                    </div>
                    <form
                        className='p-2 py-4 md:p-8 bg-white mx-auto w-[90%] lg:max-w-[30vw] md:w-[70%] lg:w-[90%] mt-11 mb-16 md:my-16 rounded-3xl'
                        onSubmit={handleSubmit}
                    >
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <input
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder='Name *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <input
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder='Email *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <input
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder='Phone *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            {/* <input
                                name="topic"
                                value={formData.topic}
                                onChange={handleChange}
                                placeholder='Topic *'
                                className='focus:outline-none placeholder:text-gray-500 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
                            /> */}
                             <select
                name="topic"
                value={formData.topic}
                onChange={handleChange}
                className='focus:outline-none placeholder:text-gray-200 tracking-widest border-gray-300 border p-4 w-full rounded-xl'
            >
                <option value="" disabled>Select a Topic *</option>
                <option value="Buying">Buying</option>
                <option value="Selling">Selling</option>
                <option value="Renting">Renting</option>
                <option value="Investing">Investing</option>
                <option value="Area Information">Area Information</option>
                <option value="Other">Other</option>
            </select>
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder='Your Message *'
                                className='focus:outline-none placeholder:text-gray-400 tracking-widest border-gray-300 border p-4 w-full md:w-[60%] rounded-xl'
                                rows={10}
                            />
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4'>
                        <div className='flex w-full my-4 mt-[26px] gap-1 '>
                            <input id='terms2' placeholder='Topic *' type='checkbox' className='focus:outline-none tracking-widest border-gray-200 border p-4 align-top self-start rounded-xl' />
                            <label htmlFor='terms2' className='text-xs text-[#022541] creato-f-l text-[15px] tracking-wider md:w-[50%]' style={{textAlign:'justify'}}>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'>Privacy Policy</span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices.
                            </label></div>
                        </div>
                        <div className='absolute bottom-0 right-0 hidden 1630px:block '>
                            <AnimationDiv
                                initial={{ x: '50%' }} // Start off-screen to the right
                                whileInView={{ x: '0%' }} // Slide in to the original position
                                transition={{ type: 'spring', stiffness: 260, damping: 20, duration: 1, delay: 0.1 }}
                            >
                                <img src={owner} alt="" className='max-w-[26vw] md:w-auto' />
                            </AnimationDiv>
                        </div>
                        <div className='flex w-full px-4 md:px-8 my-4 mt-[20px]'>
                            <button type="submit" className='btn-primary py-3 bg-theme_yellow text-white rounded-full text-nowrap px-14 font-extrabold tracking-widest' style={{ fontSize: 14 }}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='flex flex-col md:flex-row p-6 flex-wrap lg:pb-[46px] lg:pt-[60px] max-w-[1500px] mx-auto'>
                <div className='flex-1 flex items-center'>
                    <p className='creato-f-black text-[22px] max-w-[537px] md:text-[30px] leading-[30px] tracking-wide text-theme_blue'>
                        Top Real Estate Insights Delivered,
                        Straight to Your Inbox.
                    </p>
                </div>
                <div className='flex-1 mt-10'>
                    <div className='max-w-[594px] mx-auto'>
                        <div className=''><input placeholder='Enter Your Email *' className='placeholder:text-gray-500 placeholder:opacity-80 text-[19px] border border-gray-500 px-8 py-5 w-full rounded-xl placeholder:tracking-wider' /></div>
                        <div className='flex w-full my-4 mt-[26px] gap-1 '>
                            <input id='terms2' placeholder='Topic *' type='checkbox' className='focus:outline-none tracking-widest border-gray-200 border p-4 align-top self-start rounded-xl' />
                            <label htmlFor='terms2' className='text-xs text-[#022541] creato-f-l text-[15px] tracking-wider'>By providing LUXLIFE. your contact information, you acknowledge and agree to our <span className='underline cursor-pointer'>Privacy Policy</span> and consent to receiving marketing communications, including through automated calls, texts, and emails, some of which may use artificial or prerecorded voices. This consent isn’t necessary for purchasing any products or services and you may opt out at any time. To opt out from texts, you can reply, ‘stop’ at any time. To opt out from emails, you can click on the unsubscribe link in the emails. Message and data rates may apply.
                            </label></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
