import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import InvestmentMiami from './pages/investment-miami';
import Contact from './pages/Contact';
import Subscribe from './pages/Subscribe';
import MapPage from './pages/MapPage';
import New100Listing from './pages/New100Listing';
import { ConfigProvider } from 'antd';
import HomesPage from './pages/HomesPage';
import Condos from './pages/Condos';
import SellProperties from './pages/SellProperties';
import About from './pages/About';
import SingleProperty from './pages/SingleProperty';
import ScrollToTop from './components/Scroll/ScrollTop';
import PreConstruct from './pages/PreConstruct';
import SinglePreContruction from './pages/SinglePreConstruction';
import { LoginSignUp } from './components/auth/LoginSignup';
import Blog from './pages/Blog';
import SingleBlog from './pages/SingleBlog';
import { DataProvider, useData } from './context/DataContext';
import { useContext, useEffect } from 'react';
import { getPropertyTypes } from './api/GET';
import { ToastContainer } from 'react-toastify';

function App() {
  const { setKeyData } = useData()

  const checkLogin = () => {
    let loginData = localStorage.getItem('user_data')
    try {
      if (loginData) {
        loginData = JSON.parse(loginData)
        setKeyData('isLogin', loginData)
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    const fetchPropertyType = async () => {

      const data = await getPropertyTypes();
      if (Array.isArray(data.data)) {
        setKeyData('propertyType', data.data.map(v => ({ label: v, link: `/new-100/${v.replaceAll(" ", "-").replaceAll('/', '-')}` })))
      }
    }

    fetchPropertyType()
    checkLogin()
  }, [])

  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            handleColor: '#E4A231'
            , handleActiveOutlineColor: '#E4A231',
            handleLineWidth: 5

          },
          Dropdown: {
            zIndexPopup: 100000

          },
          Input: {
            colorBgContainer: '#fff', // Input background color
            colorBorder: '#d1d5db', // Input border color
            colorText: '#E4A231',
          },
        },
        token: {
          // Seed Token
          colorPrimary: '#E4A231',
          colorBorder: '#E4A231',
          // Alias Token
          colorBgContainer: '#f7f5f5',

        },
      }}
    >
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/homes' element={<HomesPage />} />
          <Route path='/about' element={<About />} />
          <Route path='/Condos' element={<Condos />} />
          <Route path='/miami/:slug' element={<InvestmentMiami />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/subscribe' element={<Subscribe />} />
          <Route path='/map' element={<MapPage />} />
          <Route path='/new-100/:tab' element={<New100Listing />} />
          <Route path='/sell-properties' element={<SellProperties />} />
          <Route path='/single-property/:id' element={<SingleProperty />} />
          <Route path='/pre-construction' element={<PreConstruct />} />
          <Route path='/pre-construction/:id' element={<SinglePreContruction />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:id' element={<SingleBlog />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
