import React, { useCallback, useEffect, useRef, useState } from 'react'
import Header from '../components/ui/header/Header'
import Map from '../components/map/Map'
import Filter from '../components/ui/filter/Filter'
import Card from '../components/cards/Card'
import { RxCaretSort } from 'react-icons/rx'
import Footer from '../components/ui/sections/FooterSection/Footer'
import miamiLogo from '../assets/images/miami-logo.png'
import sec3logo from '../assets/images/sec4_logo.svg';
import SortDropdown from '../components/forms/dropdown/SortDropdown'
import { useNavigate, useParams } from 'react-router-dom'
import LuxuryListingsSlider from '../components/carousel/LuxuryListCarousel'
import { LoadingOutlined } from '@ant-design/icons';
import { getListingData } from '../api/GET'
import { debounce } from 'lodash';
import { Spin } from 'antd'
const InvestmentMiami = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(true);
const [fitlerQuery, setfitlerQuery] = useState('OriginalListPrice.gte=1000000')
const [sortedData, setSortedData] = useState('sortBy=OnMarketDate&order=asc')
    const [listingData, setlistingData] = useState([])
    const [loadMore, setLoadMore] = useState(false);
const containerRef = useRef(null);
    

const routes = {
        'investment-miami': 'Investment properties in Miami',
        'villas-and-estates': 'Villas and Estates for Sale in Miami',
        'family-homes-sale': 'Family Homes for Sale in Miami',
        'luxury-appartment-sale': 'Luxury Apartments for Sale in Miami',
        'on-filter': "Here's what we found based on your applied Filter",
    }


    useEffect(() => {
        if (!routes[slug]) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true)
            const query = {
                offset: 0,
                limit: 15,
               
            }
            const searchParams = new URLSearchParams(query).toString();

            const data = await getListingData(
                `${searchParams ? `&${searchParams}` : ''}${fitlerQuery ? `&${fitlerQuery}` : ''}${sortedData ? `&${sortedData}` : ''}`
            );
            
            if (Array.isArray(data?.data?.data)) {
                setProperties(data?.data?.data)
                setlistingData(data?.data)
            }
            setLoading(false)
        }

        fetchProperties()
    }, [fitlerQuery,sortedData])

    const onScrollEnd = async () => {
        setLoadMore(true)
        const currendData = { ...listingData };

        if (currendData?.has_more) {
          
            const query = {
                offset: currendData.next_offset,
                limit: 15,
            }
            const searchParams = new URLSearchParams(query).toString();


            const data = await getListingData(`&${searchParams}&${fitlerQuery}${sortedData?`&${sortedData}`:'&'}`);

            if (Array.isArray(data?.data?.data)) {
                setProperties(pre=>([...pre,...data?.data?.data]))
            }

            if ((data?.data)) {
                const newData = {
                    ...data.data,
                    data: [...listingData.data, ...data.data?.data]
                }
                setlistingData(newData)
            }

        }
        setLoadMore(false)
    };

    // Debounced scroll handler that waits 200ms after scrolling stops
    const handleScroll = useCallback(
        debounce((event) => {
            const container = event.target;
            // Check if we've scrolled to the bottom of the container
            const isAtBottom =
                container.scrollHeight - container.scrollTop <= container.clientHeight + 1;

            if (isAtBottom) {
                onScrollEnd?.();
            }
        }, 200),
        [onScrollEnd]
    );

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            // Add scroll event listener to the container
            container.addEventListener('scroll', handleScroll);

            // Cleanup
            return () => {
                handleScroll.cancel();
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    return (
        routes[slug] && <div>
            <div className=' bg-theme_blue'>
                <Header />
            </div>
            <div className='p-4  py-[20px] lg:pl-[70px] lg:pr-[48px] bg-theme_yellow flex gap-10 justify-center'>
                <Filter setfitlerQuery={setfitlerQuery} />
            </div>
            <div className='flex flex-col lg:flex-row  justify-between '>
                <div className='px-2 md:pl-[2%] pt-[45px] md:pr-[2%] flex-1 lg:w-[55vw]   '>
                    <h1 className='creato-f-l text-[30px]'>
                        {routes[slug]}
                    </h1>
                    <div className='flex justify-between mt-[23px] text-gray-400'>
                        <p className='flex items-center'><span><SortDropdown setSortedData={setSortedData} /></span></p>
                        <p><span>{properties.length} result</span></p>

                    </div>
                    <Spin size="large" spinning={loading} indicator={<LoadingOutlined spin />}>

                        <div ref={containerRef} className='flex justify-center gap-x-4 flex-wrap mt-[35px] overflow-y-auto lg:h-[calc(100vh-160px)] px-[2%]'>
                            {properties.map(data => <Card data={data} className={`lg:w-[20vw] shadow-xl min-w-[300px]`} />)}
                        </div>
                        <Spin size="large" spinning={loadMore} indicator={<LoadingOutlined spin />}>
                        {loadMore ? <div className='min-h-[100px] mx-auto'> </div> : <></>}

                    </Spin>
                    </Spin>
                </div>
                <div className='lg:min-w-[45vw] w-[100vw] lg:w-[45vw] h-[300px] lg:h-[100vh] relative mt-8 lg:mt-0'>
                    <Map  data={properties}/>
                </div>
            </div>
            <div className='py-4'>
                <div className='max-w-[1300px] mx-auto text-center my-14 text-theme_grey2 text-[18px] leading-[24px] tracking-wider px-8'>

                    The data relating to real estate for sale on this website comes in part from the Miami Association of Realtors, Realtor Association of Greater Ft. Lauderdale, and the South Broward Board of Realtors. Information is deemed reliable but not guaranteed. Copyright © 2024, Miami Association of Realtors, Realtor Association of Greater Ft. Lauderdale, and the South Broward Board of Realtors. All rights reserved. The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. The use of search facilities of data on the site, other than a consumer looking to purchase real estate, is prohibited.
                </div>
                <div className='flex justify-center gap-6 md:gap-12 flex-wrap items-center' >
                    <img alt='' src={miamiLogo} className='max-w-[300px]' />
                    <img alt='' src={sec3logo} className='max-w-[300px]' />
                </div>
            </div>

            <div className='py-4 mt-10'>
                <LuxuryListingsSlider />
            </div>
            <Footer />

        </div>
    )
}

export default InvestmentMiami