import React from 'react'
import bed from '../../assets/images/bed.png'
import bath from '../../assets/images/bath.png'
import area from '../../assets/images/area.png'
import { FaLocationDot } from "react-icons/fa6";
import Button from '../buttons/Button';
import { Link } from 'react-router-dom';
import Image from '../../assets/images/android-chrome-512x512.png'

const Card = ({ data, className, property_url }) => {
    return (
        <div className={`w-[350px] my-4 max-h-[max-content]  min-h-[20vw] rounded-2xl overflow-hidden black_white_effect2 group cursor-pointer transition duration-300 ${className}`}>
           <Link to={`/single-property/${data?.ListingID}`}>
            <div className="h-[220px] lg:h-[11.5vw] overflow-hidden" style={{background:'#fff'}}>
                <img src={data?.Photos && data?.Photos[0]?.MediaURL ? data?.Photos[0]?.MediaURL : Image} alt='' width={'100%'} className='h-[220px] lg:h-[11.5vw] transition duration-300 min-w-[350px]' style={data?.Photos && data?.Photos[0]?.MediaURL ? { objectFit: 'cover' } : { objectFit: 'contain'}} />
            </div>
           </Link>
            <div className="bg-white p-[5%] rounded-b-2xl">
                <div className="md:text-[18px] xl:text-[1.2vw] flex justify-between	poppins-bold text-theme_yellow font-semibold text-start mb-2 leading-[27px] xl:leading-[1.6vw]">
                <Link to={`/single-property/${data?.ListingID}`}>
                    {/* <p>{data.PropertySubType || data?.PropertyDetails?.PropertyType}
                    </p> */}
                   <p>{data?.ListingInfo?.StreetAddress?.PostalCode} {data?.ListingInfo?.Location?.CountyOrParish}</p>
                    </Link>
                    <p className='text-[12px] text-green-500 sm:hidden'>{data?.ListingInfo?.MLSDetails?.StandardStatus}</p>
                </div>
                <div className="flex  flex-col justify-between gap-2 flex-wrap poppins-regular">

                    <div className='flex justify-between'>
                        <div className="flex items-center gap-2   ">
                            <img src={bed} alt='' className='w-5 lg:w-[1vw]' /> <p className='text-[#101010]  text-[12px] md:text-[0.7vw] text-nowrap leading-[18px]'>{data?.PropertyDetails?.Bedrooms} Bed</p>
                            <img src={bath} alt='' className='w-5 lg:w-[1vw]' /> <p className='text-[#101010]  text-[12px] md:text-[0.7vw] text-nowrap leading-[18px]'>{data?.PropertyDetails?.BathroomsTotal} Bath</p>
                            <img src={area} alt='' className='w-5 lg:w-[1vw]' /> <p className='text-[#101010]  text-[12px] md:text-[0.7vw] text-nowrap leading-[18px]'>{data?.PropertyDetails?.LivingArea} SQFT</p>
                        </div>
                        <div className='hidden sm:block'>
                            <p className='text-[12px] md:text-[0.7vw]  text-green-500'>{data?.ListingInfo?.MLSDetails?.StandardStatus}</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-1  " >
                        <FaLocationDot className='text-[13px] lg:text-[0.7vw]' /> <p className='text-[#101010] 	 text-[11px] md:text-[0.6vw]  text-nowrap leading-[18px]'>{data.City}, {data?.ListingInfo?.Location?.CountyOrParish}
                        </p>
                    </div>

                </div>

                <div className='flex mt-[4%] justify-between items-center'>
                    <p className='text-lg md:text-[1.2vw]  poppins-bold '>${data?.ListingInfo?.PriceInfo?.OriginalListPrice ? data?.ListingInfo?.PriceInfo?.OriginalListPrice?.toLocaleString() : data?.ListingInfo?.PriceInfo?.ListPrice?.toLocaleString()}</p>
                    {
                        !property_url ? <div>
                            <Button title={'Book Now'} className={'py-[9px] max-w-[95px] text-[13px] rounded-xl poppins-semibold'} />
                        </div> :
                            <Link to={`/single-property/${data?.ListingID}`}>
                                <Button title={'Book Now'} className={'py-[9px] max-w-[95px] text-[13px] rounded-xl poppins-semibold'} />
                            </Link>
                    }
                </div>

            </div>
        </div>
    )
}

export default Card