import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import Button from '../buttons/Button';
import { useData } from '../../context/DataContext';
import { toast } from 'react-toastify';
import { apiAxios } from '../../lib/axios';
import { errorAlert, formatDateYYYMMDD } from '../../lib/helper';
import AuthModal from '../auth/AuthModal';

const DatePicker = ({ allData }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [tourType, setTourType] = useState('In Person');
    const { data } = useData()

    const daysToShow = 1;
    const timeSlots = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"]
        ;

    const formatDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return {
            day: days[date.getDay()],
            date: date.getDate().toString().padStart(2, '0'),
            month: months[date.getMonth()],
            fullDate: date.toDateString(), // To uniquely identify each date
        };
    };

    const getDates = () => {
        const dates = [];
        for (let i = 0; i < 3; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            dates.push(formatDate(date));
        }
        return dates;
    };

    const handlePrevious = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - daysToShow);
        setCurrentDate(newDate);
        setSelectedDate(null); // Reset selected date when navigating
    };

    const handleNext = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + daysToShow);
        setCurrentDate(newDate);
        setSelectedDate(null); // Reset selected date when navigating
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date.fullDate);
    };

    const handleBookAppointment = async () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        if (!data?.isLogin) return toast.error('Please Login to Continue');
        if (!selectedDate) return toast.warning('Please Select an Appointment Date');
        if (!selectedTime) return toast.warning('Please Select Time ');
        if (new Date(selectedDate) < tomorrow) return toast.warning('Please select a date at least one day in the future.');


        try {
            const payload = {
                user_id: data?.isLogin?.user_id,
                property_id: allData?.ListingID,
                date: formatDateYYYMMDD(new Date(selectedDate)),
                time: selectedTime,
                type: 'viewing',
                status: 'pending'
            }
            const response = await apiAxios.post('/appointments/store', payload)

            toast.success('Your Appointment has been booked successfully')
            setSelectedDate(null);
        } catch (error) {
            errorAlert(error)
        }

    }
    return (
        <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-theme_blue text-white p-6 rounded-2xl  mx-auto"
        >
            <h2 className="creato-f-b lg:text-[1.8vw] mb-[5%] text-center">Request a Tour</h2>
            <motion.div
                className="flex justify-between gap-2    mb-[5%]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, staggerChildren: 0.1 }}
            >
                {getDates().map((date, index) => (
                    <motion.div
                        key={index}
                        className={`text-center border-[3px] rounded-2xl creato-f-b p-[3%] min-w-[90px] lg:min-w-[6.2vw] cursor-pointer 
                                    ${selectedDate === date.fullDate ? 'bg-theme_yellow text-theme_blue' : ''}`}
                        onClick={() => handleDateSelect(date)}
                        whileHover={{ scale: 1.05 }}
                        transition={{ type: 'tween', stiffness: 300 }}
                    >
                        <div className="text-sm">{date.day}</div>
                        <div className="creato-f-black lg:text-[2.5vw] py-2">{date.date}</div>
                        <div className="text-sm">{date.month}</div>
                    </motion.div>
                ))}
            </motion.div>
            <div className="flex justify-center mb-[3%] text-theme_blue">
                <motion.button
                    onClick={handlePrevious}
                    className="mr-2 bg-white p-2 rounded-full"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.2 }}
                >
                    <FaChevronLeft />
                </motion.button>
                <motion.button
                    onClick={handleNext}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className='bg-white p-2 rounded-full'
                    transition={{ duration: 0.2 }}
                >
                    <FaChevronRight />
                </motion.button>
            </div>
            <div className="mb-[3%] mt-[8%]">
                <label className="block text-sm font-medium mb-1 creato-f-b">Choose a time</label>
                <motion.div className="relative" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                    <select
                        value={selectedTime}
                        onChange={(e) => setSelectedTime(e.target.value)}
                        className="block appearance-none w-full bg-white border border-gray-700 text-theme_blue py-[4%] px-4 pr-8 rounded-full leading-tight focus:outline-none"
                    >
                        <option value="">Select</option>
                        {timeSlots.map((time, index) => (
                            <option key={index} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                    <motion.div
                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-[2.5%] bg-theme_blue text-white rounded-full my-2 mr-4"
                        whileHover={{ rotate: 180 }}
                        transition={{ duration: 0.3 }}
                    >
                        <FaChevronDown className='lg:text-[1vw]' />
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className="flex justify-center my-4 mt-8"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 200 }}
            >
                <div className='flex gap-2 bg-white p-2 px-4 rounded-full creato-f-b text-sm'>
                    <motion.button
                        onClick={() => setTourType('In Person')}
                        className={`mr-2 px-4 py-2 rounded-full ${tourType === 'In Person' ? 'bg-theme_blue text-theme_yellow' : 'text-theme_blue'}`}
                        whileHover={{ scale: 1.05 }}
                    >
                        In Person
                    </motion.button>
                    <motion.button
                        onClick={() => setTourType('Via Video Chat')}
                        className={`px-4 py-2 rounded-full ${tourType === 'Via Video Chat' ? 'bg-theme_blue text-theme_yellow' : 'text-theme_blue'}`}
                        whileHover={{ scale: 1.05 }}
                    >
                        Via Video Chat
                    </motion.button>
                </div>
            </motion.div>
            <motion.div
                className='flex mt-8'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
            >
                {
                    data?.isLogin ? <Button onClick={handleBookAppointment} title={'Book'} className='mx-auto py-2 px-8 text-theme_blue btn2 bg-white creato-f-b' /> :
                        <div className='mx-auto'>
                            <AuthModal>  <Button title={'Book'} className='mx-auto py-2 px-8 text-theme_blue btn2 bg-white creato-f-b' /></AuthModal>
                        </div>
                }
            </motion.div>
        </motion.div>
    );
};

export default DatePicker;
