import React from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import avatar_bg from '../assets/images/avatar_bg.png'
import avatar from '../assets/images/about_avatar.svg'
import AgentCard from '../components/cards/AgentCard'
import { motion } from 'framer-motion'
import { slideBottom, slideLeft } from '../components/animation/AnimationDiv'
const About = () => {
    return (
        <ContactLayout>

            <div className='flex pt-10 justify-center lg:gap-6 xl:gap-16 flex-wrap sell-property-bg'>
                <motion.div className='lg:w-[20%] relative'
                    variants={slideLeft}
                    initial="hidden"
                    animate="visible"
                >
                    <img src={avatar} alt="" className='object-cover align-bottom min-w-[600px] lg:min-w-[20%] ' />

                </motion.div>
                <motion.div className='max-w-[40%] text-theme_blue py-16 lg:min-w-[600px] text-center lg:text-start'
                    variants={slideBottom}
                    initial="hidden"
                    animate="visible"
                >
                    <h2 className='text-[48px] creato-f-b text-theme_yellow '>
                        Daniel Pansky
                    </h2>
                    <div className=' w-[50px] h-1 bg-theme_blue mx-auto lg:mx-0'></div>
                    <h1 className='creato-f-m mt-2'> Top Luxury Real Estate broker in Miami</h1>
                    <p className='creato-f-r mt-3 lg:text-[20px] px-4 lg:px-0 '>
                        Daniel Pansky is a distinguished luxury real estate broker with LuxLife, specializing in the Miami real estate market. With over 20 years of experience, Daniel is renowned for his expertise in serving ultra-high-net-worth clients with property investments ranging from $3 million to over $100 million. His extensive background as a venture capitalist and serial entrepreneur equips him with deep knowledge in investment strategies and wealth management, both in European and domestic markets.

                        As a leading figure in luxury real estate, Daniel oversees a portfolio that includes over 75
                        pre-construction development condo properties in the Miami area. His established relationships with major developers grant his clients exclusive access to prestigious projects. Daniel's extensive network and industry connections ensure that his discerning clientele receive exceptional opportunities for investment in the thriving Miami real estate market

                        Contact Daniel Pansky now for unparalleled access to Miami's most exclusive real estate opportunities and expert investment guidance tailored to your needs.
                    </p>
                </motion.div>
                <div className='py-14 '>
                    <AgentCard />
                </div>
            </div>
        </ContactLayout>
    )
}

export default About