import React, { useEffect, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import BlueBgListView from '../components/ui/sections/blueBgSection/list-view-section'
import BlueBgCardView from '../components/ui/sections/blueBgSection/card-view-section'
import Button from '../components/buttons/Button'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { getCondos, getHome } from '../api/GET'

const HomesPage = () => {
    const [properties, setProperties] = useState([])
    const [loading, setloading] = useState(false)
    useEffect(() => {
        const fetchProperties = async () => {
setloading(true)
            const data = await getHome();
            if (Array.isArray(data.data?.data)) {
                setProperties(data.data?.data)
            }
setloading(false)

        }

        fetchProperties()
    }, [])
    return (
        <ContactLayout>
            <BlueBgListView title={'Miami Homes'} />
            <div className='mb-24'>
                <BlueBgCardView title={'Homes'} propertyData={properties} loading={loading} >
                    <div className='flex justify-center mt-14'>
                    <Link to="/new-100/homes-100">
                        <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-white px-8 text-theme_yellow rounded-md py-3 creato-f-m  tracking-wide btn2'} />
                    </Link>
                    </div>
                </BlueBgCardView>
            </div>
        </ContactLayout>
    )
}

export default HomesPage