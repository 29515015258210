import React from 'react';
import Header from '../../header/Header';
import owner from '../../../../assets/images/owner.svg';
import owner2 from '../../../../assets/images/owner_hero.svg';
import herobg from '../../../../assets/images/hero_bg.jpg';
import SimpleSearch from '../../../forms/searchbar/SimpleSearch';
import AnimationDiv from '../../../animation/AnimationDiv';

const Hero = () => {
    return (
        <div className=' max-h-[calc(100vh-100px)] overflow-hidden relative'>
            <img alt='hero' src={herobg} className='w-full absolute z-[-1] lg:relative object-cover h-full  min-h-[500px]' />
            <div className='fixed top-0 w-full z-50'>
                <Header />
            </div>
            <div className='flex flex-col lg:flex-row sm:pl-10 h-full mt-16 lg:mt-0 '>
                <AnimationDiv
                    initial={{ opacity: 0, x: -300 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className='lg:absolute top-[25%]'
                >
                    <div className="hero_left text-white text-center lg:text-start flex flex-col justify-center lg:min-w-[480px]">
                        <p className='text-[3vw] lg:text-[2.5vw] font-light mt-12'>Homes and Condos For Sale</p>
                        <h1 className='text-[5vw] lg:text-[4vw] creato-f-b leading-tight'>
                            Miami and South <br />
                            Florida Real Estate
                        </h1>
                        <div className='mt-4 px-2 mx-auto lg:mx-0 flex justify-center max-w-[400px] lg:max-w-full  w-full'>
                            <SimpleSearch
                                placeholder={"Search Luxlife by Condo, Home, Listing or MLS Number"}
                                className={'rounded-full border border-theme_blue text-[16px] w-[100%] mx-auto sm:mx-0'}
                            />
                        </div>
                    </div>
                </AnimationDiv>

                <div className="hero_right flex items-center justify-center">
                    <AnimationDiv
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='lg:absolute bottom-0 right-[10%] xl:right-[20%] w-full max-w-[36%]'
                    >
                        <div className='relative flex justify-center'>
                            <img src={owner} className='object-cover hidden lg:block max-h-full lg:min-w-[50vw]   lg:mb-[-33px] ' />
                            <img src={owner2} className='lg:hidden mx-auto min-w-[300px] max-h-full object-cover ml-24 lg:mt-[-110px]' />
                            <div
                                className='absolute bottom-10 text-center lg:hidden flex flex-col text-white w-full min-w-[220px]'
                                style={{
                                    background: '#00000036',
                                    padding: '10px',
                                }}
                            >
                                <h2 className='text-[25px] leading-[27px] creato-f-r drop-shadow-lg'>
                                    DANIEL PANSKY
                                </h2>
                                <p className='text-[12px] drop-shadow-lg' style={{ fontWeight: 600, letterSpacing: '1.1px' }}>
                                    Licensed Real Estate Broker
                                </p>
                            </div>
                        </div>
                    </AnimationDiv>
                </div>
            </div>
        </div>
    );
};

export default Hero;
