import React, { useEffect, useState } from 'react'
import Hero from '../components/ui/sections/HeroSection/Hero'
import Section2 from '../components/ui/sections/section_2/Section2'
import Section3 from '../components/ui/sections/section_3/Section3'
import Section4 from '../components/ui/sections/section_4/Section4'
import Section5 from '../components/ui/sections/section5/Section5'
import ContactUs from '../components/ui/sections/ContactUsSection/ContactUs'
import ChatWidget from '../components/chat/ChatBox'
import MeetExpertSec from '../components/ui/sections/meet_expert_section/MeetExpertSec'
import Footer from '../components/ui/sections/FooterSection/Footer'
import { getListingData } from '../api/GET'

const Home = () => {
    const [properties, setProperties] = useState([])
    const [filterQuery, setfilterQuery] = useState('OriginalListPrice.gte=1000000&ListingAgreement=Exclusive Right To Sell')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true)
            const data = await getListingData(`&sortBy=OriginalListPrice&limit=15&PropertySubType.in=Condominium&PropertySubType.in=Apartment&PropertySubType.in=Single Family Residence&PropertySubType.in=Townhouse&PropertySubType.in=Residential&PropertySubType.in=Villa&PropertySubType.in=Multi Family&PropertySubType.in=Income/MultiFamily&PropertySubType.in=Duplex&PropertySubType.in=TownHouse&order=desc&${filterQuery}`);
            if (Array.isArray(data?.data?.data)) {
                setProperties(data?.data?.data)
            }
            setLoading(false)
        }

        fetchProperties()
    }, [filterQuery])


    return (
        <div className="">
            <Hero />
            <Section2 />
            <Section3 />
            <Section4 setfilterQuery={setfilterQuery} />
            {/* <MeetExpertSec /> */}
            <Section5 propertyData={properties} loading={loading} />
            <ContactUs />
            <ChatWidget />
            <Footer />
        </div>
    )
}

export default Home