import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { LoginSignUp } from './LoginSignup';
const AuthModal = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('Sign in')
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div onClick={showModal}>{children}</div>
            <Modal maskClosable={false} className='rounded-[30px] p- overflow-hidden' zIndex={99999999} centered footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <LoginSignUp title={title} setTitle={setTitle} handleClose={handleCancel} />
            </Modal>
        </>
    );
};
export default AuthModal;