import React, { useEffect, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import BlueBgListView from '../components/ui/sections/blueBgSection/list-view-section'
import BlueBgCardView from '../components/ui/sections/blueBgSection/card-view-section'
import Button from '../components/buttons/Button'
import { IoIosArrowForward } from 'react-icons/io'
import { getCondos } from '../api/GET'
import { Link } from 'react-router-dom'

const Condos = () => {

    const [loading, setloading] = useState(false)
    const [properties, setProperties] = useState([])
    useEffect(() => {
        const fetchProperties = async () => {
            setloading(true)

            const data = await getCondos();
            
            if (Array.isArray(data.data?.data)) {
                setProperties(data.data?.data)
            }
            setloading(false)
        }

        fetchProperties()
    }, [])

    return (
        <ContactLayout>
            <BlueBgListView title={'Miami Condos'} />
            <div className='mb-24'>
                <BlueBgCardView title={'Condos'} propertyData={properties} loading={loading} >
                    <div className='flex justify-center mt-14'>
                        <Link to="/new-100/condos">
                        <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-white px-8 text-theme_yellow rounded-md py-3 creato-f-m  tracking-wide btn2'} />
                        </Link>
                    </div>
                </BlueBgCardView>
            </div>
        </ContactLayout>
    )
}

export default Condos