import React, { useEffect, useState } from 'react';
import ContactLayout from '../components/ui/layout/ContactLayout';
import sec5logo from '../assets/images/hero_bg.jpg';
import { BsArrowUpRight } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import { IoIosArrowForward } from 'react-icons/io';
import Button from '../components/buttons/Button';
import { useNavigate } from 'react-router-dom';

const PreConstruct = () => {
    const [cardData, setCardData] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/get/PreConstruction');
                if (response.ok) {
                    const data = await response.json();
                    if (data.data && data?.data?.length) {

                        setCardData(data?.data.map(v => ({ ...v, photos: JSON.parse(v.photos) })));
                    }
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const handleNavigate = (data) => {
        navigate(`/pre-construction/${data.id}`)
    }

    return (
        <ContactLayout>
            <div className="relative w-full max-h-[500px] md:max-h-[600px] min-h-[300px] lg:max-h-[700px]">
                <img src={sec5logo} alt="Hero Image" className="w-full h-full max-h-[500px] md:max-h-[600px] min-h-[300px] lg:max-h-[700px] object-cover" />
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute top-[50%] left-6 transform -translate-y-1/2 text-white px-4 md:px-8 w-full">
                    <p className='text-[4vw]'>Luxlife.</p>
                    <h2 className="text-[24px] md:text-[48px] lg:text-[3.2vw] creato-f-black tracking-wide mx-auto">
                        New Development
                    </h2>
                    <p className="text-[16px] md:text-[20px] lg:text-[23px] creato-f-r mt-2 max-w-[500px]">
                        Discover all of Miami's new preconstruction condo developments.
                    </p>
                    <div className='flex mt-14'>
                        <Button title={<div className='flex items-center gap-4'><span>View more</span> <span><IoIosArrowForward className='text-[20px]' /></span></div>} className={'bg-theme_yellow px-8 text-theme_blue rounded-full py-3 creato-f-m tracking-wide btn2'} />
                    </div>
                </div>
            </div>

            <div className="container lg:max-w-[80%] mx-auto px-4">
                <div className="flex justify-center text-center md:text-start lg:justify-between flex-col lg:flex-row items-center lg:items-baseline mt-16 mb-8">
                    <h1 className="creato-f-black text-theme_blue text-[25px] leading-[30px] md:text-[3vw] md:leading-[3vw]">
                        Next-Gen <br />
                        New Developments
                    </h1>
                    <p className="max-w-[600px] lg:text-end md:text-[30px] text-wrap break-words mt-4 lg:mt-0">
                        Explore transformative new buildings that redefine modern luxury living.
                    </p>
                </div>

                <div className="flex flex-wrap gap-4 lg:gap-6 justify-center py-8">
                    {cardData.length > 0 ? (
                        cardData.map((card) => (
                            <div
                                onClick={() => handleNavigate(card)}
                                key={card.id}
                                className="relative dark-bottom-overlay overflow-hidden group cursor-pointer max-w-[460px] lg:max-w-[22vw] rounded-xl"
                            >
                                <img
                                    alt={card.title}
                                    src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${card?.photos[0]}`} // Ensure that your API provides an image URL here
                                    className="group-hover:scale-[1.1] transition duration-300 w-[100%] black_white_effect"
                                />
                                <div className="absolute z-30 bottom-4 w-full px-4 text-white">
                                    <p className="text-[22px] leading-[33px] poppins-semibold">
                                        {card.title}
                                    </p>
                                    <div className="p-2 flex gap-1 items-center">
                                        <CiLocationOn className="text-white text-[23px] transform transition-transform duration-700" />
                                        <p className="tracking-wider">{card?.address}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </ContactLayout>
    );
};

export default PreConstruct;
