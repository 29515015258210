import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContactLayout from '../components/ui/layout/ContactLayout'
import AnimationDiv from '../components/animation/AnimationDiv'
import Card from '../components/cards/Card';
import ListingsUI from '../components/tabs/TabNew100listing';
import { Empty, Spin, Switch } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useData } from '../context/DataContext';
import { getAllCities, getListingCondosData, getListingPropertyData, getListingExpensiveData, getListingHomeData, getListingVillaData } from '../api/GET';
import { LoadingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';


export const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const tabs = [
    { tab: 'listing', label: '100 Newest Listings', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'penthouses', label: '100 Newest Penthouses', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'condos', label: '100 Newest Condos', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'homes-100', label: '100 Newest Homes', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
    { tab: 'expensive', label: '100 Most Expensive', content: 'This Luxlife list shows the 100 newest luxury listings in and around Miami. The condos and homes shown below are listed for $0 and higher. Perhaps this is the time to make that first and best offer!' },
];







const ScrollGrid = ({ listingData, onScrollEnd }) => {
    const containerRef = useRef(null);

    // Debounced scroll handler that waits 200ms after scrolling stops
    // const handleScroll = useCallback(
    //     debounce((event) => {
    //         const container = event.target;
    //         // Check if we've scrolled to the bottom of the container
    //         const isAtBottom =
    //             container.scrollHeight - container.scrollTop <= container.clientHeight + 1;

    //         if (isAtBottom) {
    //             onScrollEnd?.();
    //         }
    //     }, 200),
    //     [onScrollEnd]
    // );

    // useEffect(() => {
    //     const container = containerRef.current;
    //     if (container) {
    //         // Add scroll event listener to the container
    //         container.addEventListener('scroll', handleScroll);

    //         // Cleanup
    //         return () => {
    //             handleScroll.cancel();
    //             container.removeEventListener('scroll', handleScroll);
    //         };
    //     }
    // }, [handleScroll]);

    return (
        <div
            ref={containerRef}
            className="justify-center flex-wrap xl:max-w-[80%] grid lg:grid-cols-2 min-h-[200px] xl:grid-cols-3 gap-x-4 mx-auto overflow-y-auto"
        // style={{ maxHeight: '600px' }} // Adjust this height as needed
        >
            {Array.isArray(listingData?.data) && listingData?.data?.length ? (
                listingData.data.map((v, i) => (v?.ListingID !== null) && (
                    <div
                        key={i}
                        className="lg:w-full"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.2 }}
                    >
                        <Card
                            className="lg:w-full shadow-lg"
                            property_url={true}
                            data={v}
                        >
                        </Card>
                    </div>
                ))
            ) : (
                <>
                    <div className='flex justify-center col-span-3'>
                        <Empty className='mx-auto' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                </>
            )}
        </div>
    );
};





const New100Listing = () => {
    const { tab } = useParams();
    const location = useLocation()
    const { data } = useData()
    // console.log('tabs.find(v => v.tab === `/new-100/${tab}`) ?? tabs[0]?.tab',tabs.find(v => v?.tab === tab));

    const [activeTab, setActiveTab] = useState(tabs.find(v => v.tab === tab) ?? tabs[0]);
    const [areas, setAreas] = useState([]);
    const [listingData, setlistingData] = useState([])
    const [priceRange, setPriceRange] = useState([1000000, 5000001]);
    const [loading, setLoading] = useState(true);
    const [loadMore, setLoadMore] = useState(false);

    useEffect(() => {
        // if (Array.isArray(data?.propertyType) && data?.propertyType?.length) {
        setActiveTab(tabs.find(v => v.tab === tab) ?? tabs[0])
        // }
    }, [tab])
    useEffect(() => {
        const fetchCities = async () => {
            const city_data = await getAllCities();

            if (Array.isArray(city_data?.data?.uniqueCities)) {
                const selectedArea=city_data.data?.uniqueCities.map(v => ({ name: v, selected: (Array.isArray(location.state )&& location.state.includes(v)) ? true : false }))
                setAreas(selectedArea)

                setLoading(true);
    
                const query = {
                    offset: 0,
                    limit: 100,
                    'OriginalListPrice.gte': priceRange[0],
                    ...(priceRange[1] !== 5000001 && { 'OriginalListPrice.lte': priceRange[1] })
                };
                
                // Add City.in parameters based on selected areas
                const searchParams = new URLSearchParams(query);
               if(!location.state){
                if (areas && areas.length !== 0) {
                    areas
                        .filter(v => v.selected)
                        .forEach(v => searchParams.append("City.in", v.name));
                }
               }else{
                if (selectedArea && selectedArea.length !== 0) {
                    selectedArea
                        .filter(v => v.selected)
                        .forEach(v => searchParams.append("City.in", v.name));
                }
               }
            
                const data = tab === 'expensive'
                    ? await getListingExpensiveData(searchParams.toString())
                    : tab === 'homes-100' ? await getListingHomeData(searchParams.toString())
                    : tab === 'condos' ? await getListingCondosData(searchParams.toString())
                    : tab === 'penthouses' ? await getListingVillaData(searchParams.toString())
                    : await getListingPropertyData(searchParams.toString());
            
                // Set listing data if available
                if (data?.data) {
                    setlistingData(data.data);
                }
                setLoading(false);
            }
            window.history.replaceState({}, '')

        }
        fetchCities()
    }, [])

    const fetchListing = async () => {
        setLoading(true);
    
        const query = {
            offset: 0,
            limit: 100,
            'OriginalListPrice.gte': priceRange[0],
            ...(priceRange[1] !== 5000001 && { 'OriginalListPrice.lte': priceRange[1] })
        };
        
        // Add City.in parameters based on selected areas
        const searchParams = new URLSearchParams(query);
        if (areas && areas.length !== 0) {
            areas
                .filter(v => v.selected)
                .forEach(v => searchParams.append("City.in", v.name));
        }
    
        const data = tab === 'expensive'
            ? await getListingExpensiveData(searchParams.toString())
            : tab === 'homes-100' ? await getListingHomeData(searchParams.toString())
            : tab === 'condos' ? await getListingCondosData(searchParams.toString())
            : tab === 'penthouses' ? await getListingVillaData(searchParams.toString())
            : await getListingPropertyData(searchParams.toString());
    
        // Set listing data if available
        if (data?.data) {
            setlistingData(data.data);
        }
        setLoading(false);
    };
    
    useEffect(() => {
        setLoading(true)

        fetchListing()
    }, [tab])

    const handleSearchFilter = () => {
        setLoading(true)
        fetchListing()
        setLoading(false)
    }
    const handleScrollEnd = async () => {
        setLoadMore(true)
        // Your function to call when scroll ends within the card container
        console.log('Container scroll ended, loading more cards...', listingData);
        // Add your logic here
        const currendData = { ...listingData };

        if (currendData?.has_more) {
            const query = {
                offset: currendData.next_offset,
                limit: 15,
                PropertySubType: tab ? tab?.replaceAll('-', ' ') : "",
                City: (areas && areas.length !== 0 ? areas.map(v => v.selected ? v.name : null).filter(Boolean)[0] : "") ?? ''
            }
            const searchParams = new URLSearchParams(query).toString();


            const data = await getListingPropertyData(searchParams);

            if ((data?.data)) {
                const newData = {
                    ...data.data,
                    data: [...listingData.data, ...data.data?.data]
                }
                setlistingData(newData)
            }

        }
        setLoadMore(false)
    };

    return (
        <ContactLayout>
            <div>
                <h2 className='text-[32px] md:text-[3vw] creato-f-b text-center tracking-wide text-theme_blue mt-[75px]'>The Luxlife</h2>
            </div>
            <ListingsUI activeTab={activeTab} setActiveTab={setActiveTab} priceRange={priceRange} setPriceRange={setPriceRange} tabs={tabs} areas={areas} setAreas={setAreas} handleSearchFilter={handleSearchFilter} />
            <div className='px-[2%] '>
                <div className='p-4 mt-14'>
                    <h1 className='text-theme_blue md:text-[45px] creato-f-b'>{activeTab?.label}</h1>

                </div>
                <Spin size="large" spinning={loading} indicator={<LoadingOutlined spin />}>
                    <ScrollGrid
                        listingData={listingData}
                        onScrollEnd={handleScrollEnd}
                    />
                    <Spin size="large" spinning={loadMore} indicator={<LoadingOutlined spin />}>
                        {loadMore ? <div className='min-h-[100px] w-full'> </div> : <></>}

                    </Spin>
                </Spin>
                <div className='max-w-[1400px] segoeui text-[14px] leading-[19px] text-theme_grey2 text-center mx-auto py-10 px-4'>
                    The properties above are provided by Miami Association of Realtors, and represent properties found in Aventura, Miami Beach, Sunny Isles Beach, Bal Harbour, Surfside, Miami, Key Biscayne. Data updated 8/5/2024. Daniel Pansky is licensed with Luxlife as a cooperating broker or cooperating agent. The information above believed to be accurate, but is not guaranteed. The data for these properties come from the Internet Data Exchange (IDX), a collaboration between brokers and REALTOR® associations in which brokers allow their listings to be syndicated to websites hosted by cooperating REALTOR® member brokers. Brokers appearing on this website as the contact person for any property is not necessarily the listing agent. The information being provided is for consumers' personal, non-commercial use. Federal law prohibits discrimination on the basis of race, color, religion, sex, handicap, familial status or national origin in the sale, rental or financing of housing.
                </div>
            </div>
        </ContactLayout>
    )
}

export default New100Listing