import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';

// Fix marker icons issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Custom Marker Icon
const customIcon = L.divIcon({
    className: 'custom-marker', // Optional: Add custom class for styling
    html: `<div style="width: 20px; height: 20px; border-radius:10px; border:4px white solid; background-color: black;"></div>`,
    iconSize: [30, 30], // Optional: Adjust the size of the icon container
});

// Custom component to handle zoom and centering
const UpdateMapView = ({ coords }) => {
    const map = useMap();

    useEffect(() => {
        if (coords) {
            const { Latitude, Longitude } = coords;
            map.setView([Latitude, Longitude], 10); // Adjust zoom level as needed
        }
    }, [coords, map]);

    return null;
};

const MapComponent = ({ coords, nameLocation,data }) => {
    const position = coords ? [coords.Latitude, coords.Longitude] : [51.505, -0.09];
    const navigate = useNavigate();
    const handleMarkerClick = (id) => {
        navigate(`/single-property/${id}`);
    };

    return (
        <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
       {data?data.map(v=>v?.ListingInfo?.Location?.Latitude?<Marker position={[v.ListingInfo.Location.Latitude,v.ListingInfo.Location.Longitude]} icon={customIcon}  eventHandlers={{
                            click: () => handleMarkerClick(v.ListingID),
                        }}>
                {v?.ListingInfo ? <Popup> {v?.ListingInfo?.StreetAddress?.PostalCode} {v?.ListingInfo?.Location?.CountyOrParish}</Popup> : <></>}
            </Marker>:null)
            :     <Marker position={position} icon={customIcon}>
                {nameLocation ? <Popup>{nameLocation}</Popup> : <></>}
            </Marker>}
            {/* This component will handle the dynamic map update */}
            <UpdateMapView coords={Array.isArray(data) &&data.length>0&&data[0].ListingInfo.Location.Latitude?{Latitude:data[0].ListingInfo.Location.Latitude,Longitude:data[0].ListingInfo.Location.Longitude}: coords} />
        </MapContainer>
    );
};

export default MapComponent;
